
import gsap from 'gsap';
import { outerHeight, outerWidth } from "./helpers";
var headings =gsap.utils.toArray(".expertise-list > li")
// Get Content + Padding + Border
const listItem = document.querySelector('.expertise-list > li')
const listItemWidth = outerWidth(listItem);
// const listItemHeight = listItem.offsetHeight;
const listItemHeight = outerHeight(listItem);
console.log(listItemWidth, listItemHeight)

var next = 2; // time to change

function verticalMovingText(){
    var action = gsap.timeline()
    .to(headings,  {y:`-=${listItemHeight}`, opacity: 1, duration:1})
    .to(headings[0], {y:`+=${listItemHeight*6}`, duration:0}) // the first to the end

    headings.push( headings.shift() ); // the first (shift) to the end (push) from the array
    // console.log(headings);
    // start endless run
    gsap.delayedCall(next, verticalMovingText);
}

verticalMovingText()