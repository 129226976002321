import {Bar, toggleNav} from '../js/main'
import '../css/reset.css'
import '../css/constants.css'
import '../css/main.css'
import './index.css'
import './text-scroller';
import './hero-webgl-3';
// import './image-scroller';
import './three-sphere'
// import './hero-webgl'
// gsap.to(document.querySelector('body'), {
//     visibility: "visible",
// })
toggleNav()
console.log('this is index/home page');
// const canvas = document.querySelector('.webgl');
// const nav = document.querySelector('nav');
//  const sizes = {
//   width: window.innerWidth,
//   height: window.innerHeight,
//   navHeight: nav.offsetHeight || 0,
// }
// canvas.width = sizes.width
// canvas.height = sizes.height - sizes.navHeight

// window.addEventListener('resize', () =>
// {
//   // Update sizes
//   sizes.width = window.innerWidth
//   sizes.height = window.innerHeight
//   sizes.navHeight = nav.offsetHeight || 0,
//   // console.log(sizes)
//   canvas.width = sizes.width
//   canvas.height = sizes.height - sizes.navHeight

// })