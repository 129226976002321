import gsap from 'gsap'
let invocation = 0


const productsNavLink = document.querySelector('.products-nav-link')
const productsMenuLinks = document.querySelectorAll('.productMenu > a > p')
const whitePagesNavLink = document.querySelector('.white-pages-nav-link')
const whitePagesMenuLink = document.querySelectorAll('.whitePagesMenu > a > p')

productsNavLink.addEventListener('mouseover', ()=> {entryLinks(productsMenuLinks)})
whitePagesNavLink.addEventListener('mouseover', ()=> {entryLinks(whitePagesMenuLink)})
// whitePagesNavLink.addEventListener('mouseout', ()=> {exitLinks(whitePageMenuItems)})


function entryLinks(linksArray){
  gsap.killTweensOf(linksArray)
  .fromTo(linksArray, {
    opacity: 0.,
    x: 4,
    duration: 0.1,
    ease: 'power4.out'
  }, {
    stagger: 0.04,
    opacity: 1,
    x: 0,
  })
}
function exitLinks(){
  gsap.killTweensOf(navMenuItems)
  .fromTo(navMenuItems, {
    opacity: 1,
    x: 0,
    duration: 0.1,
  }, {
    stagger: 0.1,
    opacity: 0,
    x: 5,
    ease: 'sine'
  })
}

const Bar = () => {
  invocation++
  return `barfoo ${invocation}`
}

function toggleNav(){
  document.querySelector('.hamburgerIcon').addEventListener('click', ()=> {
    document.querySelector(".slider-menu").classList.toggle("slider-menu-animation")
    document.querySelector('.ham').classList.toggle('active')
  })
}
export { Bar, toggleNav }
