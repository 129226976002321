// https://github.com/janarosmonaliev/github-globe
// https://github.com/maxolib/world
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import gsap from 'gsap'
import landVertexShader from './shaders/land/vertex.glsl';
import landFragmentShader from './shaders/land/fragment.glsl';
import Stats from 'stats.js'

var stats = new Stats();
stats.showPanel( 0 ); // 0: fps, 1: ms, 2: mb, 3+: custom
// document.body.appendChild( stats.dom );

/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.sphere-webgl')

const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('/draco/')
// Scene
const scene = new THREE.Scene()
const textureLoader = new THREE.TextureLoader()
const dotTexture = textureLoader.load('textures/world/dot_256.jpg')
// const oceanMatcap = textureLoader.load('textures/world/ocean-matcap.png')
const oceanMatcap = textureLoader.load('textures/world/blue-red-2.png')
// oceanMatcap.rotation = Math.PI/2

var loaded
const gltfLoader = new GLTFLoader()
gltfLoader.setDRACOLoader(dracoLoader)
var land = new THREE.Points()
gltfLoader.load(
    './models/world/world_geometry_vertex-2.glb',
    (model) => {
        const landMesh = model.scene.children[0]
        const landGeometry = landMesh.geometry
        const landMaterial = new THREE.PointsMaterial({
            color: 0xefefef,
            // color: 0xefefef,
            size: 0.006,
            // sizeAttenuation: true,
            // transparent: true,

        })
        // landMaterial.onBeforeCompile = _shader => {
            // _shader.uniforms.testAlpha = { value: 1 }
            // _shader.uniforms.cameraPos = { value: camera.position }
            // _shader.uniforms.cameraOffset = { value: 2.5 }
            // _shader.uniforms.jooner = { value: 0.98 }
            // _shader.vertexShader = landVertexShader
            // _shader.fragmentShader = landFragmentShader
        // }

        landMaterial.map = dotTexture
        land = new THREE.Points(
            landGeometry,
            landMaterial,
        )

        // Load ocean mesh
        // const oceanMesh = model.scene.children[0] as Mesh
        const oceanGeometry = new THREE.SphereBufferGeometry(1, 20, 20)
        // const oceanMaterial = new THREE.MeshStandardMaterial({
        //     color: 0x04122d
        // })
        const oceanMaterial = new THREE.MeshMatcapMaterial()
        oceanMaterial.matcap = oceanMatcap
        const ocean = new THREE.Mesh(
            oceanGeometry,
            oceanMaterial
        )
        ocean.scale.set(0.999, 0.999, 0.999)
        land.add(ocean)

        scene.add(land)
        // const model = scene.children.find(child => child.name === 'Scene')
        setUpAnimation(land)
        loaded = true
        // console.log(scene)
    }
)

// Object
const geometry = new THREE.SphereGeometry(8, 64, 64)

const material = new THREE.MeshMatcapMaterial({
    // color: 0xffffff,
    // color: 0xefefef,
})
// material.map = earthBGTexture
const mesh = new THREE.Mesh(geometry, material)
// scene.add(mesh)


// Sizes
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

// Camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100);
camera.position.set(0, 0, 1.8);
scene.add(camera);

// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true

// Lighting
// const ambientLight = new THREE.AmbientLight(0x172254, 0.8);
// scene.add(ambientLight);
// const directionalLight = new THREE.DirectionalLight(0x495ff4, 0.5); // default 3
// directionalLight.position.set(-2, 2, 1);
// camera.add(directionalLight);
// const pointRedLight = new THREE.PointLight(0xff0000, 10, 5); // default 3
// pointRedLight.position.set(1, 2, 1);
// camera.add(pointRedLight);
// const pointBlueLight = new THREE.PointLight(0x4444ff, 5, 4); // default 3
// pointBlueLight.position.set(-2.2, 1.8, -1);
// camera.add(pointBlueLight);

// Renderer
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
})
// renderer.shadowMap.enabled = true;
// renderer.shadowMap.type = THREE.PCFShadowMap;
// renderer.physicallyCorrectLights = true;
// renderer.outputEncoding = THREE.sRGBEncoding;
// renderer.toneMapping = THREE.ReinhardToneMapping;
// renderer.toneMappingExposure = 1.5;
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.setClearColor(0x0D0E11)


// Animate
const clock = new THREE.Clock()

const tick = () =>
{
    stats.begin();
    const elapsedTime = clock.getElapsedTime()
    // Update controls
    // controls.update()

    // land.rotation.y = elapsedTime*0.1;

    // Render
    renderer.render(scene, camera)
    stats.end()
    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()

function setUpAnimation(model){
    gsap.fromTo(model.rotation, {
        y: 0,
    }, {
        y: 2*Math.PI,
        duration: 20,
        ease: 'linear',
        repeat: -1
    })
}